import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const List = makeShortcode("List");
const CTA = makeShortcode("CTA");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Stablecoins`}</h1>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Learn about Cryptocurrencies Built for Stability`}</h2>
      <p>{`A stablecoin is a type of cryptocurrency that is most often backed by an underlying asset.`}</p>
      <p>{`While digital assets like Bitcoin and Ether succeed as currencies on a number of levels, they are not ideal as a medium of exchange due to their volatility.`}</p>
      <p>{`Stablecoins combine the censorship-resistance of cryptocurrencies like Bitcoin and Ether with the familiarity and ease-of-use associated with stable fiat currencies.`}</p>
    </Box>
    <h2>{`Why Stability Matters`}</h2>
    <p>{`Stablecoins make cryptocurrency more accessible to the average person. They allow decentralized exchanges (exchanges without a trusted intermediary, where users always maintain full custody of their funds) to denominate trading pairs in U.S. dollars instead of Bitcoin or Ether.`}</p>
    <p>{`Volatility in the spot price of digital assets like Bitcoin and Ethereum make it difficult, if not impossible, to use certain applications of distributed ledger technology.`}</p>
    <p>{`When using Bitcoin to send a remittance from one country to another, for example, the price movement during a single block confirmation (how long it takes the blockchain to include a transaction) may be higher than any fees charged by payment processors like Western Union or PayPal. `}</p>
    <p>{`Operating a business with thin margins, for example, can be nearly impossible when the underlying currency is so volatile.`}</p>
    <p>{`Unlike Bitcoin and Ether, the value of a stablecoin is pegged to an underlying asset like the US Dollar. The goal of stablecoin is to maintain a stable value relative to its underlying asset.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h3>{`Types of Stablecoins`}</h3>
      <p>{`Stablecoins can be categorized by their underlying collateral and how much collateral the system requires.`}</p>
      <p>{`Hypothetically, any asset of value can be used as collateral. Most stablecoins currently fall into one of three categories:`}</p>
      <List mdxType="List">
        <p>{`Asset-Backed`}</p>
        <p>{`Fiat-Backed`}</p>
        <p>{`Cryptocurrency-Backed`}</p>
      </List>
    </Box>
    <h3>{`Stablecoins May Also be:`}</h3>
    <List mdxType="List">
      <Box mdxType="Box">
        <h4>{`Over-Collateralized`}</h4>
        <p>{`These systems require a deposit of greater value than the stablecoins minted by the protocol.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Fully-Collateralized`}</h4>
        <p>{`These systems require a deposit equal of equal value to the stablecoins minted by the protocol.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Under-Collateralized`}</h4>
        <p>{`These systems operate on fractional reserves and allow deposits holding less value than the stablecoins minted by the protocol.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Non-Collateralized`}</h4>
        <p>{`These systems use algorithms that increase or decrease the amount of tokens in circulation to affect monetary policy and maintain a stable value.`}</p>
      </Box>
    </List>
    <h2>{`Dai`}</h2>
    <p>{`Dai is a stablecoin from Maker whose value is pegged to the U.S. Dollar.`}</p>
    <CTA mdxType="CTA">
      <Link mdxType="Link">
        <p><a parentName="p" {...{
            "href": "/learn/Dai/index/"
          }}>{`Learn more about Dai`}</a></p>
      </Link>
    </CTA>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      